<template>
  <div>
    <!--上传组件-->
    <el-upload
      v-if="uploadStyle === 0"
      action=""
      list-type="picture-card"
      :file-list="fileList"
      :auto-upload="true"
      :on-exceed="handleExceed"
      :before-upload="handleBeforeUpload"
      :http-request="handleUploadFile"
      :on-preview="handleFileCardPreview"
      :on-remove="handleRemove"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!--  :on-preview="handleFileCardPreview"  -->
    <!-- :file-list="fileList"   -->
    <!--  :before-upload="handleBeforeUpload" -->
    <!-- list-type="picture" -->
    <el-upload
      v-if="uploadStyle === 1"
      class="upload-demo"
      action="abc"
      :show-file-list="false"
      :multiple="true"
      :before-upload="shicha"
      :auto-upload="true"
      :on-exceed="handleExceed"
      :http-request="handleUploadFile"
    >
      <el-button size="small" type="primary">{{ btn }}</el-button>
    </el-upload>
    <el-upload
      v-if="uploadStyle === 2"
      class="upload-demo"
      drag
      multiple
      action=""
      :file-list="fileList"
      :auto-upload="true"
      :on-exceed="handleExceed"
      :before-upload="handleBeforeUpload"
      :http-request="handleUploadFile"
      :on-preview="handleFileCardPreview"
      :on-remove="handleRemove"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <!--进度条-->
    <el-progress
      v-show="showProgress"
      :text-inside="true"
      :stroke-width="15"
      :percentage="progress"
    ></el-progress>
    <!--展示上传的文件-->
    <!-- <el-dialog
      :title="dialogFileUrlTitle"
      :visible.sync="dialogFileUrlVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      append-to-body
    >
      <div v-if="dialogFileFormat === 'image'">
        <img width="100%" :src="dialogFileUrl" alt="" />
      </div>
      <div v-else-if="dialogFileFormat === 'video'">
        <video
          controls
          controlslist="nodownload"
          preload="auto"
          style="width: 98%; text-align: center"
          :src="dialogFileUrl"
        ></video>
      </div>
      <div v-else>
        <el-alert title="当前格式暂不支持预览!" type="error" center show-icon>
        </el-alert>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { client, getFileNameUUID } from "@/api/oss";
import API from "@/api/modules/app.js";
import CryptoJS from "crypto-js";
// 获取后端鉴权的接口，具体看自己业务实现。
// import { getStsPermission } from "@/api/common/common";

export default {
  name: "AliUpload",
  created() {
    this.initialize();
  },
  data() {
    return {
      // 上传样式 0:卡片照片墙 1:缩略图 2:拖拽上传文件
      uploadStyle: 2,
      // oss上传所需数据对象
      ossData: {},
      // 文件列表
      fileList: [],
      // 进度条的显示
      showProgress: false,
      // 进度条数据
      progress: 0,
      // 文件参数表单
      fileParams: {
        // 上传的目录
        folder: "/default/",
      },
      // 展示上传
      dialogFileUrlVisible: false,
      dialogFileUrlTitle: "",
      dialogFileUrl: "",
      dialogFileFormat: "",
      loading:null,
    };
  },
  model: {
    event: "complete",
    prop: "",
  },
  // 组件插槽
  props: {
    // 上传位置
    position: {
      required: true,
    },
    // 使用样式
    styleType: {},
    // 展示图片
    showFile: {
      type: String,
    },
    btn: {
      type: String,
    },
  },
  mounted() {
    // ossz直传信息
    this.ossz();
  },
  // 数据监听
  watch: {
    showFile: {
      handler() {
        this.onShowFile();
      },
    },
    position: {
      handler() {
        this.onPosition();
      },
    },
  },
  methods: {
    // 视频上传前的校验
    shicha(e) {
      // console.log(e,'砂浆款了l');
      const isSize = e.size / 1024 / 1024;
      if (isSize > 100) {
        this.$message.error("视频大小不能超过100M！");
        return false;
      }
      // console.log(e, "手机号");

      let a = e.type;
      const isExcel =
        a.split("/").pop() === "mp4" || a.split("/").pop() === "avi";
      // a.split(".")[1] === "gif" ||
      // a.split(".")[1] === "png";
      if (!isExcel) {
        this.$message.error("只能上传mp4,avi类型的图片!");
        return false;
      }
      this.shisuo = false;
      this.loading = this.$loading({
        text: "视频上传中,请稍等...",
      });
    },
    // oss直传信息
    async ossz() {
      let res = await API.alioss().then((res) => {
        // console.log(res, "数据");
        if (res.code == 1) {
          // let a = res.data.Endpoint
          // a = a.split('.')
          // res.data.Bucket = a[0]

          // 移除Base64字符串中可能存在的空格
      const encoded = res.data.result;
      let  result= this.$Base64.decode(encoded);
      let dataarr= result.split("::")
      
       let jiemi =this.decryptData(dataarr[0] , res.data.key, dataarr[1])
       jiemi =this.$Base64.decode(jiemi);
       this.ossData=JSON.parse(jiemi)
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    decrypt(str, KEY, IV) {
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var decrypt = CryptoJS.AES.decrypt(str, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
 
    return JSON.parse (decrypt.toString(CryptoJS.enc.Utf8));  //json 转为对象
  },
  decryptData(ciphertext, key, iv) {
    // 解码Base64
    var ciphertextBytes = CryptoJS.enc.Base64.parse(ciphertext);
    
    // 解密
    var decryptedBytes = CryptoJS.AES.decrypt({
        ciphertext: ciphertextBytes
    }, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    // 将解密后的字节转换为Latin1编码的字符串
    var decryptedText = decryptedBytes.toString(CryptoJS.enc.Base64);
    
    return decryptedText;
},
    /** 组件初始化 */
    initialize() {
      this.onPosition();
      this.onShowFile();
      this.uploadStyle = this.styleType;
    },

    /** showFile */
    onShowFile() {
      // console.log("showFileUrl:" + this.showFile);
      if (this.showFile) {
        let url = this.showFile;
        let name = "点击预览文件";
        this.fileList = [{ name, url }];
      } else {
        this.fileList = [];
      }
    },

    /** position */
    onPosition() {
      if (this.position === "" || this.position == null) {
        this.$message({
          message: "组件初始化失败！缺少[position]",
          type: "warning",
        });
        return;
      }
      switch (this.position) {
        case "default":
          this.fileParams.folder = "/default/";
          break;
        case "system":
          this.fileParams.folder = "/system/";
          break;
        case "post":
          this.fileParams.folder = "/post/";
          break;
        case "circle":
          this.fileParams.folder = "/circle/";
          break;
        case "newsPicture":
          this.fileParams.folder = "/news/picture/";
          break;
        case "newsVideo":
          this.fileParams.folder = "/news/video/";
          break;
        case "liveGift":
          this.fileParams.folder = "/live/gift/";
          break;
        default:
          this.$message({
            message: "组件初始化失败！未知[position]",
            type: "warning",
          });
          return;
      }
    },

    /** 上传文件之前 */
    handleBeforeUpload(file) {
      this.$emit("upload-success", false);
      // 加载OSS配置参数
      return new Promise((resolve, reject) => {
        getStsPermission()
          .then((response) => {
            this.ossData = response.data;
            resolve(true);
          })
          .catch((error) => {
            this.$message({
              message: "加载上传配置失败！error msg:" + error,
              type: "warning",
            });
            reject(false);
          });
      });
    },

    /** 文件超出个数限制 */
    handleExceed(files, fileList) {
      this.$message({
        message: "停！不能再多了～",
        type: "warning",
      });
    },

    /** 文件列表移除文件 */
    handleRemove(file, fileList) {
      this.$message({
        message: "成功移除一个文件～",
        type: "success",
      });
    },

    /** 点击文件列表中已上传的文件 */
    handleFileCardPreview(file) {
      let fileFormat = this.judgeFileFormat(file.url);
      switch (fileFormat) {
        case "image":
          this.dialogFileUrlTitle = "图片预览";
          break;
        case "video":
          this.dialogFileUrlTitle = "视频预览";
          break;
        default:
          this.$message.error(`当前格式为${fileFormat},暂不支持预览！`);
          return;
      }
      this.dialogFileFormat = fileFormat;
      this.dialogFileUrl = file.url;
      this.dialogFileUrlVisible = true;
    },

    /** 根据URL判断文件格式 */
    judgeFileFormat(fileUrl) {
      // 获取最后一个.的位置
      const index = fileUrl.lastIndexOf(".");
      // 获取后缀
      const suffix = fileUrl.substr(index + 1);
      console.log(`当前文件后缀格式为 suffix: ${suffix}`);
      // 获取类型结果
      let result = "";
      // 图片格式
      const imgList = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imgList.find((item) => item === suffix);
      if (result) {
        return "image";
      }
      // 匹配txt
      const txtList = ["txt"];
      result = txtList.find((item) => item === suffix);
      if (result) {
        return "txt";
      }
      // 匹配 excel
      const excelList = ["xls", "xlsx"];
      result = excelList.find((item) => item === suffix);
      if (result) {
        return "excel";
      }
      // 匹配 word
      const wordList = ["doc", "docx"];
      result = wordList.find((item) => item === suffix);
      if (result) {
        return "word";
      }
      // 匹配 pdf
      const pdfList = ["pdf"];
      result = pdfList.find((item) => item === suffix);
      if (result) {
        return "pdf";
      }
      // 匹配 ppt
      const pptList = ["ppt", "pptx"];
      result = pptList.find((item) => item === suffix);
      if (result) {
        return "ppt";
      }
      // 匹配 视频
      const videoList = [
        "mp4",
        "m2v",
        "mkv",
        "rmvb",
        "wmv",
        "avi",
        "flv",
        "mov",
        "m4v",
      ];
      result = videoList.find((item) => item === suffix);
      if (result) {
        return "video";
      }
      // 匹配 音频
      const radioList = ["mp3", "wav", "wmv"];
      result = radioList.find((item) => item === suffix);
      if (result) {
        return "radio";
      }
      // 其他 文件类型
      return "other";
    },

    /** 执行文件上传 */
    handleUploadFile(file) {
      let that = this;
      // 选中目录
      let folder = that.fileParams.folder;
      // 环境判断
      let active = that.ossData.active;
      // 正式与测试环境存储分离
      if (active !== null && active !== undefined) {
        if (active === "dev" || active === "test") {
          folder = "/test" + folder;
        }
      }
      // oss-host
      // let host = that.ossData.Endpoint;

      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // 月份从0开始，需要加1
        const day = now.getDate();
        let a = year.toString() + month.toString() + day.toString();
        // 获取文件名
        const fileName = file.file.name;
        const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
        // MD5加密
        let b = CryptoJS.MD5(fileNameWithoutExtension).toString();
        // 文件路径和时间 MD5 文件格式
        // let fileNameUrl = folder + getFileNameUUID() + "." + fileFormat;
        let fileNameUrl = `/uploads/yandu/${a}/${b}.${fileFormat}`;
        const resliceSize = 4 * 1024 * 1024; // 2MB
        client(that.ossData)
          .multipartUpload(fileNameUrl, file.file, {
            progress: function (plan) {
              // that.showProgress = true;
              // that.progress = Math.floor(plan * 100);
            },
            resliceSize,//设置分片大小
          })
          .then((result) => {
            that.loading.close();
            // console.log(result.res,'砂浆款了');
            // 获取地址,格式为数组
            let data = result.res.requestUrls;
            // 返给父组件
            that.$parent.complete(data);
            //　文件上传成功后填充输入框
            let uploadResult = host + fileNameUrl;
            // that.$emit("complete", uploadResult);
            // that.$emit("upload-success", true);
            // that.showProgress = false;
          })
          .catch((error) => {
            that.loading.close();
            // console.log(error,'刷卡机');
            // 不知道为什么返回这个错误,但是似乎没有问题
            if(error == 'ReferenceError: host is not defined'){
              return
            }

            that.$message({
              message: "当前网络波动大,请稍后重试!",
              type: "warning",
            });
          });
      }

      multipartUpload();
    },
  },
};
</script>

<style scoped>
</style>;
